/* !important: this file should only contain general mapbox styles that would
 * still be appropriate if this mapbox component was broken off into its own
 * package */

.mapbox-default {
  width: 100%;
  height: 100%;
}

.marker {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  fill: #fff;
  border-radius: 50%;
  background: #47a9f9;
  border: solid 2px #fff;
  display: flex;
  padding: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.marker.is-active {
  background: dodgerblue;
  z-index: 999;
}

.marker.is-editing {
  border-style: dashed;
}

.marker.is-inactive {
  background: #ccc;
}
.marker.is-inactive.hover,
.marker.is-inactive:hover,
.marker.hover,
.marker:hover {
  background: dodgerblue;
  z-index: 999;
}

.marker.is-fixed,
.marker.is-fixed.hover,
.marker.is-fixed:hover,
.marker.is-fixed.hover,
.marker.is-fixed:hover {
  background: rgba(255, 160, 160, 0.8);
  z-index: 999;
}

.mapboxgl-popup-content {
  border-radius: 5px;
  z-index: 9998;
  padding: 10px 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.mapboxgl-popup-close-button {
  opacity: 0;
}
.mapboxgl-popup-tip {
  z-index: 9999;
}

.mapbox-info {
  position: fixed;
  font-size: 11px;
  right: 20px;
  bottom: 40px;
  padding: 0 15px;
  width: 200px;
  background: white;
  border: solid 2px #ddd;
  border-radius: 10px;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-gl-draw_trash {
  display: none !important;
}
.mapbox-show-trash .mapbox-gl-draw_trash {
  display: block !important;
}

.map-edit .mapboxgl-ctrl-bottom-right {
  bottom: 65px;
}

/* {{{ mouse cursor controls */
.mapboxgl-map.mouse-pointer .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: pointer;
}
.mapboxgl-map.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mouse-add .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: crosshair;
}
.mapboxgl-map.mouse-move.mode-direct_select .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.mapboxgl-map.mode-direct_select.feature-vertex.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mode-direct_select.feature-midpoint.mouse-pointer .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: cell;
}
.mapboxgl-map.mode-direct_select.feature-feature.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mode-static.mouse-pointer  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
/* }}} */

